/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { get } from 'lodash';

// the block of reducers that work well with actions generated by actionUtils.generateRequestActions
export const isLoading = ({ start, success, failure }) => (state = false, { type }) => {
  switch (type) {
  case start:
    return true;
  case failure:
  case success:
    return false;
  default:
    return state;
  }
};

export const isLoaded = ({ start, success, failure }) => (state = false, { type }) => {
  switch (type) {
  case start:
  case failure:
    return false;
  case success:
    return true;
  default:
    return state;
  }
};

export const errorMessageReducer = ({ start, success, failure }) =>
  (state = null, { type, errorMessage }) => {
    switch (type) {
    case start:
    case success:
      return null;
    case failure:
      return errorMessage;
    default:
      return state;
    }
  };

export const errorMessageId = ({ start, success, failure }) =>
  (state = null, { type, errorId }) => {
    switch (type) {
    case start:
    case success:
      return null;
    case failure:
      return errorId;
    default:
      return state;
    }
  };

/**
 * Extracts value from action using {@see pathToValue} and put it in a state
 * @param {string} actionType - action type to listen
 * @param {string[]|string} pathToValue
 */
export const extractValue = (actionType, pathToValue) => (state = null, action) => {
  switch (action.type) {
  case actionType:
    return pathToValue ? get(action, pathToValue, null) : action;
  default:
    return state;
  }
};
