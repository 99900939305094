/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import Immutable from 'immutable';

import TreeNode from './TreeNode';

@Radium
class Tree extends Component {
  static propTypes = {
    resourceTree: PropTypes.instanceOf(Immutable.List),
    renderNode: PropTypes.func,
    isNodeExpanded: PropTypes.func,
    selectedNodeId: PropTypes.string
  }

  static defaultProps = {
    resourceTree: Immutable.List()
  }

  renderNodes = () => this.props.resourceTree.map((node, index) => (
    <TreeNode
      node={node}
      key={index}
      renderNode={this.props.renderNode}
      isNodeExpanded={this.props.isNodeExpanded}
      selectedNodeId={this.props.selectedNodeId}
    />
  ))

  render() {
    return (
      <div style={style} className='global-tree'>
        {this.renderNodes()}
      </div>
    );
  }
}

const style = {
  width: '100%'
};

export default Tree;
