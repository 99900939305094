/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isAuthorized, authInfoPropType, rulePropType, getAuthInfoSelector } from '@app/utils/authUtils';

const mapStateToProps = state => ({
  authInfo: getAuthInfoSelector(state)
});

export class AuthorizationWrapperView extends Component {
  static propTypes = {
    rule: rulePropType.isRequired,
    children: PropTypes.func, // (isAuthorized: boolean)
    scope: PropTypes.any, //a scope for children function

    // connected props
    authInfo: authInfoPropType.isRequired
  }

  render() {
    const {
      rule,
      authInfo,
      children,
      scope
    } = this.props;

    return children.call(scope, isAuthorized(rule, authInfo));
  }
}

const AuthorizationWrapper = connect(mapStateToProps)(AuthorizationWrapperView);

export class RestrictedArea extends Component {
  static propTypes = {
    rule: rulePropType.isRequired,
    children: PropTypes.any // will be path through
  }

  renderInternal = (isAuthorizedFlag) => {
    if (isAuthorizedFlag) {
      return this.props.children;
    }
    return null;
  }

  render() {
    const {
      rule
    } = this.props;

    return (<AuthorizationWrapper rule={rule}>
      {this.renderInternal}
    </AuthorizationWrapper>);
  }
}
