/*
 * Copyright (C) 2017-2019 Dremio Corporation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */


class AddFileModalMapper {
  mapFilePayload(payload) {
    return payload && payload.fileConfig && payload.fileConfig.fullPath;
  }

  mapFileFormatPayload(payload) {
    return payload;
  }

  escapeFilePayload = fileData => (
    Immutable.fromJS(JSON.parse(JSON.stringify(fileData)
      .replace(/\\n/g, '\\\\n')
      .replace(/\\r/g, '\\\\r')
      .replace(/\\t/g, '\\\\t')
    ))
  )

  unescapeFilePayload = fileData => (
    JSON.stringify(fileData)
      .replace(/\\\\n/g, '\\n')
      .replace(/\\\\r/g, '\\r')
      .replace(/\\\\t/g, '\\t')
  )
}

const addFileModalMapper = new AddFileModalMapper();

export default addFileModalMapper;

