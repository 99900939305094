module.exports = {
	"./Activity.svg": require("./Activity.svg"),
	"./Add.svg": require("./Add.svg"),
	"./AddFields.svg": require("./AddFields.svg"),
	"./AddHover.svg": require("./AddHover.svg"),
	"./Aggregate.svg": require("./Aggregate.svg"),
	"./Aggregation.svg": require("./Aggregation.svg"),
	"./AggregationReversed.svg": require("./AggregationReversed.svg"),
	"./ArrowDownSmall.svg": require("./ArrowDownSmall.svg"),
	"./ArrowDownSmallReversed.svg": require("./ArrowDownSmallReversed.svg"),
	"./ArrowLeft.svg": require("./ArrowLeft.svg"),
	"./ArrowRight.svg": require("./ArrowRight.svg"),
	"./Bars.svg": require("./Bars.svg"),
	"./Blocked.svg": require("./Blocked.svg"),
	"./Bug.svg": require("./Bug.svg"),
	"./Canceled.svg": require("./Canceled.svg"),
	"./CanceledGray.svg": require("./CanceledGray.svg"),
	"./Chat.svg": require("./Chat.svg"),
	"./ChatActive.svg": require("./ChatActive.svg"),
	"./ChatTeal.svg": require("./ChatTeal.svg"),
	"./Clipboard.svg": require("./Clipboard.svg"),
	"./Collapse.svg": require("./Collapse.svg"),
	"./copy.svg": require("./copy.svg"),
	"./Data.svg": require("./Data.svg"),
	"./Database.svg": require("./Database.svg"),
	"./DatabaseBad.svg": require("./DatabaseBad.svg"),
	"./DatabaseDegraded.svg": require("./DatabaseDegraded.svg"),
	"./DatabaseGray.svg": require("./DatabaseGray.svg"),
	"./DataGraph.svg": require("./DataGraph.svg"),
	"./Decommissioning.svg": require("./Decommissioning.svg"),
	"./Delete.svg": require("./Delete.svg"),
	"./Disabled.svg": require("./Disabled.svg"),
	"./Disconnected.svg": require("./Disconnected.svg"),
	"./DiskSpill.svg": require("./DiskSpill.svg"),
	"./Download.svg": require("./Download.svg"),
	"./Draggable.svg": require("./Draggable.svg"),
	"./Edit.svg": require("./Edit.svg"),
	"./EditActive.svg": require("./EditActive.svg"),
	"./EditSmall.svg": require("./EditSmall.svg"),
	"./Ellipsis.svg": require("./Ellipsis.svg"),
	"./EngineStart.svg": require("./EngineStart.svg"),
	"./Error.svg": require("./Error.svg"),
	"./ErrorSolid.svg": require("./ErrorSolid.svg"),
	"./Expand.svg": require("./Expand.svg"),
	"./ExpandText.svg": require("./ExpandText.svg"),
	"./ExpandWiki.svg": require("./ExpandWiki.svg"),
	"./File.svg": require("./File.svg"),
	"./FileConvert.svg": require("./FileConvert.svg"),
	"./FileEmpty.svg": require("./FileEmpty.svg"),
	"./FileLarge.svg": require("./FileLarge.svg"),
	"./Flame.svg": require("./Flame.svg"),
	"./FlameDisabled.svg": require("./FlameDisabled.svg"),
	"./FlameSnowflake.svg": require("./FlameSnowflake.svg"),
	"./Folder.svg": require("./Folder.svg"),
	"./FolderConvert.svg": require("./FolderConvert.svg"),
	"./FolderData.svg": require("./FolderData.svg"),
	"./FolderDataLarge.svg": require("./FolderDataLarge.svg"),
	"./FolderToDataset.svg": require("./FolderToDataset.svg"),
	"./GroupBy.svg": require("./GroupBy.svg"),
	"./HeaderData.svg": require("./HeaderData.svg"),
	"./HeaderGraph.svg": require("./HeaderGraph.svg"),
	"./HeaderMetadata.svg": require("./HeaderMetadata.svg"),
	"./HeaderReflection.svg": require("./HeaderReflection.svg"),
	"./Home.svg": require("./Home.svg"),
	"./Info.svg": require("./Info.svg"),
	"./InfoCircle.svg": require("./InfoCircle.svg"),
	"./InfoCircleNoninteractive.svg": require("./InfoCircleNoninteractive.svg"),
	"./InfoCircleSolid.svg": require("./InfoCircleSolid.svg"),
	"./Join.svg": require("./Join.svg"),
	"./JoinFull.svg": require("./JoinFull.svg"),
	"./JoinInner.svg": require("./JoinInner.svg"),
	"./JoinLeft.svg": require("./JoinLeft.svg"),
	"./JoinRight.svg": require("./JoinRight.svg"),
	"./Loader.svg": require("./Loader.svg"),
	"./LoaderWhite.svg": require("./LoaderWhite.svg"),
	"./Lookup.svg": require("./Lookup.svg"),
	"./Masked.svg": require("./Masked.svg"),
	"./Minus.svg": require("./Minus.svg"),
	"./NarwhalLogo.svg": require("./NarwhalLogo.svg"),
	"./NarwhalLogoWithNameLight.svg": require("./NarwhalLogoWithNameLight.svg"),
	"./NarwhalLogoWithNameLightBeta.svg": require("./NarwhalLogoWithNameLightBeta.svg"),
	"./NarwhalReversed.svg": require("./NarwhalReversed.svg"),
	"./OK.svg": require("./OK.svg"),
	"./OKSolid.svg": require("./OKSolid.svg"),
	"./OKSolidGrey.svg": require("./OKSolidGrey.svg"),
	"./Online.svg": require("./Online.svg"),
	"./OpenBI.svg": require("./OpenBI.svg"),
	"./Partition.svg": require("./Partition.svg"),
	"./Pending.svg": require("./Pending.svg"),
	"./PendingDiamond.svg": require("./PendingDiamond.svg"),
	"./PendingGray.svg": require("./PendingGray.svg"),
	"./PendingState.svg": require("./PendingState.svg"),
	"./PhysicalDataset.svg": require("./PhysicalDataset.svg"),
	"./PhysicalDatasetGray.svg": require("./PhysicalDatasetGray.svg"),
	"./PhysicalDatasetLarge.svg": require("./PhysicalDatasetLarge.svg"),
	"./Pin.svg": require("./Pin.svg"),
	"./Planning.svg": require("./Planning.svg"),
	"./PowerBi.svg": require("./PowerBi.svg"),
	"./Provisioning.svg": require("./Provisioning.svg"),
	"./provisionManagers/Amazon.svg": require("./provisionManagers/Amazon.svg"),
	"./provisionManagers/Azure.svg": require("./provisionManagers/Azure.svg"),
	"./provisionManagers/GCE.svg": require("./provisionManagers/GCE.svg"),
	"./provisionManagers/Hadoop.svg": require("./provisionManagers/Hadoop.svg"),
	"./provisionManagers/Kubernetes.svg": require("./provisionManagers/Kubernetes.svg"),
	"./provisionManagers/Mesos.svg": require("./provisionManagers/Mesos.svg"),
	"./Publish.svg": require("./Publish.svg"),
	"./Qlik.svg": require("./Qlik.svg"),
	"./Query.svg": require("./Query.svg"),
	"./QueryPlain.svg": require("./QueryPlain.svg"),
	"./Queued.svg": require("./Queued.svg"),
	"./Raw.svg": require("./Raw.svg"),
	"./RawMode.svg": require("./RawMode.svg"),
	"./RawReversed.svg": require("./RawReversed.svg"),
	"./Recent.svg": require("./Recent.svg"),
	"./Recenter.svg": require("./Recenter.svg"),
	"./Return.svg": require("./Return.svg"),
	"./RightArrow.svg": require("./RightArrow.svg"),
	"./Save.svg": require("./Save.svg"),
	"./Search.svg": require("./Search.svg"),
	"./SearchPaleNavy.svg": require("./SearchPaleNavy.svg"),
	"./Settings.svg": require("./Settings.svg"),
	"./SettingsSmall.svg": require("./SettingsSmall.svg"),
	"./Sidebar.svg": require("./Sidebar.svg"),
	"./SidebarActive.svg": require("./SidebarActive.svg"),
	"./SimpleAdd.svg": require("./SimpleAdd.svg"),
	"./SimpleCheckMark.svg": require("./SimpleCheckMark.svg"),
	"./SimpleMinus.svg": require("./SimpleMinus.svg"),
	"./sorted.svg": require("./sorted.svg"),
	"./sources/ADL.svg": require("./sources/ADL.svg"),
	"./sources/AMAZONELASTIC.svg": require("./sources/AMAZONELASTIC.svg"),
	"./sources/AWSGLUE.svg": require("./sources/AWSGLUE.svg"),
	"./sources/AZURE_STORAGE.svg": require("./sources/AZURE_STORAGE.svg"),
	"./sources/CASSANDRA.svg": require("./sources/CASSANDRA.svg"),
	"./sources/ELASTIC.svg": require("./sources/ELASTIC.svg"),
	"./sources/HBASE.svg": require("./sources/HBASE.svg"),
	"./sources/HDFS.svg": require("./sources/HDFS.svg"),
	"./sources/HIVE.svg": require("./sources/HIVE.svg"),
	"./sources/HIVE3.svg": require("./sources/HIVE3.svg"),
	"./sources/MAPRFS.svg": require("./sources/MAPRFS.svg"),
	"./sources/MONGO.svg": require("./sources/MONGO.svg"),
	"./sources/MSSQL.svg": require("./sources/MSSQL.svg"),
	"./sources/MYSQL.svg": require("./sources/MYSQL.svg"),
	"./sources/NAS.svg": require("./sources/NAS.svg"),
	"./sources/ORACLE.svg": require("./sources/ORACLE.svg"),
	"./sources/POSTGRES.svg": require("./sources/POSTGRES.svg"),
	"./sources/REDSHIFT.svg": require("./sources/REDSHIFT.svg"),
	"./sources/S3.svg": require("./sources/S3.svg"),
	"./sources/SALESFORCE.svg": require("./sources/SALESFORCE.svg"),
	"./sources/SampleSource.svg": require("./sources/SampleSource.svg"),
	"./sources/SOLR.svg": require("./sources/SOLR.svg"),
	"./sources/TERADATA.svg": require("./sources/TERADATA.svg"),
	"./Space.svg": require("./Space.svg"),
	"./SpacePrivate.svg": require("./SpacePrivate.svg"),
	"./Starting.svg": require("./Starting.svg"),
	"./StartingEngine.svg": require("./StartingEngine.svg"),
	"./StartSquare.svg": require("./StartSquare.svg"),
	"./StopSquare.svg": require("./StopSquare.svg"),
	"./Tableau.svg": require("./Tableau.svg"),
	"./Trace.svg": require("./Trace.svg"),
	"./TrashSmall.svg": require("./TrashSmall.svg"),
	"./TriangleDown.svg": require("./TriangleDown.svg"),
	"./TriangleRight.svg": require("./TriangleRight.svg"),
	"./types/Date.svg": require("./types/Date.svg"),
	"./types/Time.svg": require("./types/Time.svg"),
	"./types/TypeBinary.svg": require("./types/TypeBinary.svg"),
	"./types/TypeBoolean.svg": require("./types/TypeBoolean.svg"),
	"./types/TypeDateTime.svg": require("./types/TypeDateTime.svg"),
	"./types/TypeDecimal.svg": require("./types/TypeDecimal.svg"),
	"./types/TypeFloat.svg": require("./types/TypeFloat.svg"),
	"./types/TypeGeo.svg": require("./types/TypeGeo.svg"),
	"./types/TypeInteger.svg": require("./types/TypeInteger.svg"),
	"./types/TypeList.svg": require("./types/TypeList.svg"),
	"./types/TypeMap.svg": require("./types/TypeMap.svg"),
	"./types/TypeMixed.svg": require("./types/TypeMixed.svg"),
	"./types/TypeOther.svg": require("./types/TypeOther.svg"),
	"./types/TypeText.svg": require("./types/TypeText.svg"),
	"./Upload.svg": require("./Upload.svg"),
	"./UploadBlue.svg": require("./UploadBlue.svg"),
	"./User.svg": require("./User.svg"),
	"./UserGroup.svg": require("./UserGroup.svg"),
	"./VirtualDataset.svg": require("./VirtualDataset.svg"),
	"./VirtualDatasetLarge.svg": require("./VirtualDatasetLarge.svg"),
	"./Visibility.svg": require("./Visibility.svg"),
	"./VisibilityOff.svg": require("./VisibilityOff.svg"),
	"./Warning.svg": require("./Warning.svg"),
	"./WarningSolid.svg": require("./WarningSolid.svg"),
	"./Wiki.svg": require("./Wiki.svg"),
	"./XBig.svg": require("./XBig.svg"),
	"./XBigWhite.svg": require("./XBigWhite.svg"),
	"./XRed.svg": require("./XRed.svg"),
	"./XSmall.svg": require("./XSmall.svg")
};